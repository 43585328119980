import { useState } from "preact/hooks";
import React from "react";


export function useForm<T extends object>(initialValues: T) {
  const initialState = structuredClone(initialValues);
  const [values, setValues] = useState<T>(initialValues);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const updateValue = (name: string, value: string) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  }

  const reset = () => {
    setValues(initialState);
  }

  return {
    values,
    handleChange,
    updateValue,
    setValues,
    reset,
  };
}