import { useEffect, useRef } from "preact/hooks";

const InputMask = ({ label, name, mask, reverse = false, maskOptions, ...props }) => {
    const ref = useRef<HTMLInputElement>(null!);

    useEffect(() => {
        jQuery(ref.current).mask(mask, {
            ...maskOptions,
            reverse
        });

        return () => jQuery(ref.current).unmask();
    }, []);

    return <fieldset>
        <label htmlFor={name}>{label}</label>
        <input type="text" name={name} id={name} ref={ref} {...props} />
    </fieldset>
}

InputMask.PhoneMask = (val) => {
    return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
};
InputMask.PhoneMaskOptions = {
    onKeyPress: function(val, e, field, options) {
        field.mask(InputMask.PhoneMask.apply({}, arguments as any), options);
    },
};

export { InputMask };
