import { Select } from "../Components/Select";
import { useEffect, useRef, useState } from "preact/hooks";
import { range } from "../utils";
import { InputMask } from "../Components/InputMask";

export function Produtos({ handleChange, setValues, values }) {
    const [hasMakroWhats, setMakroWhats] = useState(false);
    const ativacaoRef = useRef(null);

    function handleMakroWhats(e) {
        const hasMakroWhats = e.target.value === '1';
        setMakroWhats(e.target.value === '1');

        if (hasMakroWhats) {
            setValues((prev) => ({
                ...prev,
                makro_whats_qtd_numeros: 1,
                makro_whats_qtd_usuarios: 1
            }));
        } else {
            setValues((prev) => ({
                ...prev,
                makro_whats_qtd_numeros: 0,
                makro_whats_qtd_usuarios: 0
            }));
        }
    }

    return <div>
        <h2>Produtos</h2>

        <Select label="Makro CND" name="makro_cnd" onChange={handleChange} required={true}>
            <option disabled={true} selected={true}>- Deseja contratar? -</option>
            <option value="1">Sim</option>
            <option value="0">Não</option>
        </Select>

        <Select label="Makro DFe" name="makro_dfe" onChange={handleChange} required={true}>
            <option disabled={true} selected={true}>- Deseja contratar? -</option>
            <option value="1">Sim</option>
            <option value="0">Não</option>
        </Select>

        <Select label="Makro DSe" name="makro_dse" onChange={handleChange} required={true}>
            <option disabled={true} selected={true}>- Deseja contratar? -</option>
            <option value="1">Sim</option>
            <option value="0">Não</option>
        </Select>

        <Select label="MakroWhats" name="makro_whats" onChange={handleMakroWhats} required={true}>
            <option disabled={true} selected={true}>- Selecione uma opção -</option>
            <option value="1">Sim</option>
            <option value="0">Não</option>
        </Select>

        { hasMakroWhats ? <>
            <Select
                label="Quantidade de Números"
                name="makro_whats_qtd_numeros"
                onChange={handleChange}
                required={true}
            >
                { range(1, 30, ).map((qty) => <option value={qty}>{qty}</option>) }
            </Select>

            <Select
                label="Quantidade de Usuários"
                name="makro_whats_qtd_usuarios"
                onChange={handleChange}
                required={true}
            >
                { range(1, 30).map((qty) => <option value={qty}>{qty}</option>) }
            </Select>

            <Select
                label="Tem API de integração?"
                name="makro_whats_api_integracao"
                onChange={handleChange}
                required={true}
            >
                <option disabled={true} selected={true}>--</option>
                <option value="1">Sim</option>
                <option value="0">Não</option>
            </Select>

            <Select
                label="Tem Chat Bot?"
                name="makro_whats_chatbot"
                onChange={handleChange}
                required={true}
            >
                <option disabled={true} selected={true}>--</option>
                <option value="1">Sim</option>
                <option value="0">Não</option>
            </Select>

            <Select
                label="Ativação"
                name="makro_whats_ativacao"
                onChange={handleChange}
                required={true}
                ref={ativacaoRef}
            >
                <option value="0" selected={true} disabled={true}>--</option>
                <option value="250">R$250,00</option>
                <option value="500">R$500,00</option>
            </Select>

            <InputMask
                name="valor_mensalidade"
                label="Valor da Mensalidade"
                mask="#.##0,00"
                reverse={true}
                onChange={handleChange}
            />
        </> : null}
    </div>
}
