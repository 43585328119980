import { Resultado } from "./Sections/Resultado";
import { useEffect, useState } from "preact/hooks";

export const baseSelectorResultado = ".calculadora-makro-result";

export function ResultadoConsulta() {
    const [loaded, setLoaded] = useState(false);
    const [isExpired, setExpired] = useState(false);
    const [data, setData] = useState<any>({});

    useEffect(() => {
        const id = new URLSearchParams(window.location.search).get('id');

        if (!id) {
            console.warn("Não foi informado o ID para resgate");
            setLoaded(true);
            return;
        };

        function loadData() {
            fetch(`https://api-leads.makrosystem.com.br/api/v1/lead/${id}`)
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        setData({});
                        setLoaded(true);
                        setExpired(true);
                        return;
                    }

                    setData(data);
                    setLoaded(true);
                })
                .catch(console.error);
        }

        loadData();
    }, []);

    return (<div className="resultado-calculadora">
        { !loaded && <div>Carregando...</div>}
        { loaded && isExpired && <div>Sua proposta expirou.</div> }
        { loaded && !isExpired && Object.keys(data).length === 0 && <div>ID não informado ou inválido</div> }
        { loaded && !isExpired && Object.keys(data).length !== 0 && <div>
			<Resultado {...data.meta} showForm={false} />
        </div>}
    </div>)
}
