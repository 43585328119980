import { render } from "preact";
import { baseSelectorCalculadora, Calculadora } from "./Calculadora";
import { baseSelectorResultado, ResultadoConsulta } from "./ResultadoConsulta";
import "./style.scss";

// Init all
document.querySelectorAll<HTMLDivElement>(baseSelectorCalculadora).forEach((element) => {
    if (element['data-initialized']) return;
    render(<Calculadora />, element);
    element['data-initialized'] = true;
});

document.querySelectorAll<HTMLDivElement>(baseSelectorResultado).forEach((element) => {
    if (element['data-initialized']) return;
    render(<ResultadoConsulta />, element);
    element['data-initialized'] = true;
});

