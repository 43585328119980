
export function Select({ name, label, children, ...props }) {
    return (
        <fieldset>
            <label htmlFor={name}>{label}</label>
            <select id={name} name={name} {...props}>
                {children}
            </select>
        </fieldset>
    );
}