
export function range(start = 0, end = Infinity, step = 1, leftPad = false, padChar = "0") {
    const result = [];
    for (let i = start; i <= end; i += step) {
        if (leftPad) {
            result.push(i.toString().padStart(2, padChar));
        } else {
            result.push(i.toString());
        }
    }
    return result;
}

export const numeroParaExtenso = {
	1: 'uma',
	2: 'duas',
	3: 'três',
	4: 'quatro',
	5: 'cinco',
	6: 'seis',
	7: 'sete',
	8: 'oito',
	9: 'nove',
	10: 'dez',
	11: 'onze',
	12: 'doze',
	13: 'treze',
	14: 'catorze',
	15: 'quinze',
	16: 'dezesseis',
	17: 'dezessete',
	18: 'dezoito',
	19: 'dezenove',
	20: 'vinte',
	21: 'vinte e uma',
	22: 'vinte e duas',
	23: 'vinte e três',
	24: 'vinte e quatro',
	25: 'vinte e cinco',
	26: 'vinte e seis',
	27: 'vinte e sete',
	28: 'vinte e oito',
	29: 'vinte e nove',
	30: 'trinta',
};

export function formatHoras(horas: number) {
	return parseInt(horas) <= 1 ? 'hora' : 'horas'
}
