import { useForm } from "./Hooks/useForm";
import { PlanoDesejado } from "./Sections/PlanoDesejado";
import { Implantacao } from "./Sections/Implantacao";
import { Produtos } from "./Sections/Produtos";
import { calcular } from "./values";
import {useRef, useState} from "preact/hooks";
import { Resultado } from "./Sections/Resultado";

export const baseSelectorCalculadora = ".calculadora-makro";

const initialValues = {
	plano_escolhido: "",
	quantidade_licencas: "",
	plano_implantacao: "",
	forma_pagamento: "",
	qtd_funcionarios: 0,
	capacitacao_adicional: "",
	migracao_dados: "",
	makro_cnd: "",
	makro_dfe: "",
	makro_dse: "",
	makro_whats: "",
	makro_whats_qtd_numeros: "",
	makro_whats_qtd_usuarios: "",
	makro_whats_api_integracao: "",
	makro_whats_chatbot: "",
	makro_whats_ativacao: 0,
	valor_mensalidade: 0,
};

export function Calculadora() {
	const formRef = useRef<HTMLFormElement | null>(null);
    const { values, handleChange, updateValue, setValues } = useForm(initialValues);
    const [result, setResult] = useState(null);

    function handleSubmit(e) {
        e.preventDefault();
        const result = calcular(values);
        setResult(result);
    }

	function resetar() {
		setValues(initialValues);
		formRef.current?.reset();
		setResult(null);
	}

    return <div className="calculadora">
		{ !result &&
			<form ref={formRef} onSubmit={handleSubmit}>
				<PlanoDesejado
					handleChange={handleChange}
					updateValue={updateValue}
					values={values}
				/>

				<Implantacao
					handleChange={handleChange}
					updateValue={updateValue}
					values={values}
				/>

				<Produtos
					handleChange={handleChange}
					updateValue={updateValue}
					values={values}
					setValues={setValues}
				/>

				<button>Simular investimento</button>
			</form>
		}

        { result && <Resultado handleReset={resetar} {...result} />}
    </div>;
}
