import { Select } from "../Components/Select";
import { range } from "../utils";
import { useState } from "preact/hooks";

export function PlanoDesejado({ handleChange, values, updateValue }) {
    const [licencas, setLicencas] = useState<number | null>(null);

    function setLicencasCount(event) {
        const value = event.target.value;
        setLicencas(30);
        updateValue("quantidade_licencas", null);
    }

    return <div>
        <h2>Qual plano você deseja?</h2>

        <Select
            label="Plano escolhido"
            name="plano_escolhido"
            value={values.plano_escolhido}
            required={true}
            onChange={(e) => {
                setLicencasCount(e);
                handleChange(e);
            }}
        >
            <option value="" disabled={true} selected={true}>- Selecione seu plano -</option>
            <option value="light">Light</option>
            <option value="basico">Básico</option>
            <option value="intermediario">Intermediário</option>
            <option value="avancado">Avançado</option>
        </Select>

        <Select
            label="Quantidade de Licenças"
            name="quantidade_licencas"
            value={values.quantidade_licencas}
            required={true}
            onChange={handleChange}
        >
            <option value="" disabled={true} selected={true}>--</option>
            {licencas && range(1, licencas, 1, true).map((i) => <option value={i}>{i}</option>)}
        </Select>
    </div>;
}
